<template>
<div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="!user">
        <h4 class="alert-heading">
            Error fetching user data
        </h4>
        <div class="alert-body">
            No user found with this user id. Check
            <b-link class="alert-link" :to="{ name: 'apps-users-list'}">
                User List
            </b-link>
            for other users.
        </div>
    </b-alert>
    <template v-if="user">
        <!-- First Row -->
        <b-row>
            <b-col cols="12" lg="12">
                <user-view-user-info-card :user-data="user" />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" lg="6">
                <user-view-user-permissions-card :user-data="user" />
            </b-col>
            <b-col cols="12" lg="6">
                <user-view-user-timeline-card :user-data="user" />
            </b-col>
        </b-row>
    </template>
</div>
</template>

<script>
import store from '@/store'
import $ from 'jquery'
import router from '@/router'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import {
    BRow,
    BCol,
    BAlert,
    BLink,
} from 'bootstrap-vue'
import InvoiceList from '@/views/invoice/invoice-list/InvoiceList.vue'
import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BRow,
        BCol,
        BAlert,
        BLink,

        // Local Components
        UserViewUserInfoCard,
        UserViewUserPlanCard,
        UserViewUserTimelineCard,
        UserViewUserPermissionsCard,

        InvoiceList,
    },
    data() {
        return {
            user: {},
        }
    },
    mounted() {
        this.fetchRecord()
        console.log("Role for you users " + this.$cookies.get("clientData"))
    },
    methods: {
        fetchRecord() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'account/v1/view/users',
                type: "get",
                async: true,
                crossDomain: true,
                data: {
                    limit: 1,
                    userId: router.currentRoute.params.id
                },
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                success: function (response, status, jQxhr) {

                    vm.user = response.data.data.data[0]
                },
                error: function (jQxhr, status, error) {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error fetching users list',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                }
            });

        }
    }
}
</script>

<style>

</style>
